import { mixins } from "@/plugins/mixins";
import balanceRechargeDetail from "./detail";
export default {
  name: "customerBalanceRecharge",
  mixins: [mixins],
  components: {
    balanceRechargeDetail
  },
  data() {
    return {
      detailDrawer: false,
      currentRow: {},
      columns: [{
        label: '用户',
        prop: 'customerName'
      }, {
        label: '电话',
        prop: 'phone'
      }, {
        label: '充值金额',
        prop: 'fee'
      }, {
        label: '赠送金额',
        prop: 'giveFee'
      }, {
        label: '充值时间',
        prop: 'rechargeTime'
      }, {
        label: '支付状态',
        prop: 'payStatus'
      }, {
        label: '交易时间',
        prop: 'tradeTime'
      }],
      topButtons: [],
      searchItems: [{
        prop: 'time',
        propItems: ['beginTime', 'endTime'],
        label: '时间范围',
        type: 'date',
        dateType: 'daterange',
        valueFormat: 'YYYY-MM-DD',
        defaultValue: [new Date().format('yyyy-MM-dd'), new Date().format('yyyy-MM-dd')],
        shortcuts: this.defaultDateShortcuts()
      }, {
        prop: 'payStatus',
        label: '支付状态',
        type: 'select',
        items: ['未支付', '部分支付', '已支付', '免费', '已取消']
      }, {
        prop: 'phone',
        label: '手机号'
      }],
      linkButtons: [{
        name: '详情',
        code: 'balanceDetail',
        type: 'primary',
        click: this.Detail,
        permissions: ['balanceDetail']
      }]
    };
  },
  methods: {
    Detail(row) {
      console.log(row);
      this.currentRow = row;
      this.detailDrawer = true;
    }
  }
};