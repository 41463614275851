import { ElMessage } from "element-plus";
import 'element-plus/es/components/message/style/css'

export default {
  name: "balanceRechargeDetail",
  props: ['transactionFlowId', 'tradeTime'],
  emits: ['parkRecordLoaded'],
  data() {
    return {
      transactionFlow: {},
      activeName: 'first',
      loading: true,
      refundModel: {
        reason: '',
        refundFee: 0
      }
    };
  },
  methods: {
    loadParkRecord() {
      this.$api.business.customerBalanceRecharge.detail({
        transactionFlowId: this.transactionFlowId,
        tradeTime: this.tradeTime
      }).then(res => {
        if (res.code === 200) {
          this.transactionFlow = res.data;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  beforeMount() {
    this.loadParkRecord();
  }
};